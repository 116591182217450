<template>
  <div>
    <v-card outlined tile>
      <v-list>
        <v-list-item>
          <v-select
            :items="folders"
            v-model="folder"
            label="폴더를 선택하세요"
            item-text="name"
            item-value="id"
            @change="changeFolder"
            outlined
            single-line
            class="pt-5"
          >
            <template slot="no-data">
              <v-alert>폴더가 없습니다</v-alert>
            </template>
          </v-select>
          <!-- <v-btn color="light-green" dark @click="searchFolder" x-large style="align-self: baseline;margin-left:5px;">검색</v-btn> -->
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-checkbox
              v-model="allSelected"
              label="전체선택"
            ></v-checkbox>
          </v-list-item-action>
          <v-spacer/>
          <v-list-item-action>
            <v-btn color="error" tile dark @click="delItems"><v-icon>mdi-delete</v-icon>선택 삭제</v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
            <v-card outlined tile height="120" width="100%" v-if="mediaItems.length <= 0">
              <v-list-item>
                <v-list-item-title class="d-flex justify-center mt-13">
                  등록된 미디어가 없습니다.
                </v-list-item-title>
              </v-list-item>
            </v-card>
            <div style="width: 100%" v-else>
              <div>미디어 : 총 {{ mediaItems.length }}개</div>
              <library-item-card
                class="pb-3"
                v-for="(item, index) in mediaItems"
                :key="index"
                :mediaItem="item"
                :allSelected="allSelected"
                :clear="clearItemCheck"
                @setItem="setItem"
                @resetItem="resetItem"
                @delItem="delItem"
                @preview="preview"
                @previewPDF="previewPDF"
              ></library-item-card>
            </div>

        </v-list-item>

      </v-list>
    </v-card>
    <v-bottom-sheet max-width="650"
      v-model="previewAudioDialog"
      inset
      open-delay
    >
      <v-card tile>
        <v-progress-linear
          :value="100"
          class="my-0"
          height="3"
        ></v-progress-linear>
        <v-list>
          <v-list-item>
            <audio controls autoplay id="audioControl" :src="selectedPreviewItem.aFileUrl" style="width:100%"></audio>
          </v-list-item>
          <v-list-item
            v-for="(item, index) in selectedPreviewItem.audioFiles"
            :key="index"
            style="border-bottom: 1px solid #d3d3d3"
          >
            <v-list-item-content>
              <v-list-item-title>{{ index + 1 }}. {{ item.title }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                fab
                x-small
                color="info"
                dark
                @click="playAudioFile(index)"
              ><v-icon>mdi-play</v-icon>
              </v-btn>
            </v-list-item-action>

          </v-list-item>
        </v-list>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet max-width="650"
      v-model="previewVideoDialog"
      inset
      open-delay
    >
      <v-card tile>
        <v-progress-linear
          :value="100"
          class="my-0"
          height="3"
        ></v-progress-linear>
        <v-list>
          <v-list-item>
            <youtube :video-id="playbackYoutubeId" ref="youtube" @playing="playing"></youtube>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ selectedPreviewItem.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ selectedPreviewItem.folder }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>
<script>
import LibraryItemCard from './LibraryItemCard'

export default {
  components: { LibraryItemCard },
  props: ['academyId', 'folders', 'clear'],
  data () {
    return {
      folder: '',
      mediaItems: [],
      allSelected: false,
      clearItemCheck: '',
      selectedItems: [],
      previewAudioDialog: false,
      previewVideoDialog: false,
      playbackYoutubeId: '',
      selectedPreviewItem: {},
      ref: null
    }
  },
  watch: {
    previewAudioDialog () {
      if (!this.previewAudioDialog) {
        const audio = document.getElementById('audioControl')
        if (audio) {
          audio.pause()
          audio.currentTime = 0
          audio.src = ''
        }
        // console.log('audio unload')
      }
    },
    previewVideoDialog () {
      if (!this.previewVideoDialog) {
        this.playbackYoutubeId = ''
        // console.log('video unload')
      }
    },
    clear (str) {
      // console.log('clear : ', str)
      this.selected = false
      this.mediaItems = []
      this.allSelected = false
      this.folder = ''
    }
  },
  computed: {
    player () {
      return this.$refs.youtube.player
    }
  },
  methods: {
    changeFolder () {
      // folder 조회
      this.allSelected = false
      const ref = this.$firebase.firestore().collection('libraries')
        .where('academy', '==', this.academyId)
        .where('folder', '==', this.folder)
        .orderBy('title')

      ref.onSnapshot((sn) => {
        if (sn.empty) {
          this.mediaItems = []
          return
        }

        this.mediaItems = sn.docs.map(v => {
          const item = v.data()
          return {
            id: v.id,
            title: item.title,
            type: item.type,
            folderId: item.folder,
            folder: this.getFolderName(item.folder),
            imgName: item.imageName,
            imgUrl: item.imageUrl,
            // aFilename: item.audioFilename,
            // aFileUrl: item.audioUrl,
            audioFiles: item.audioFiles,
            pdfFilename: item.pdfFilename,
            pdfFileUrl: item.pdfUrl,
            yUrl: item.youtubeUrl,
            dur: item.duration
          }
        })
        // console.log('###', JSON.stringify(this.mediaItems))
      })
    },
    getFolderName (id) {
      const fn = this.folders.find(f => f.id === id)
      return fn.name
    },
    setItem (id) {
      this.selectedItems = this.selectedItems.filter(function (n) {
        return n !== id
      })
      this.selectedItems.push(id)
    },
    resetItem (id) {
      this.selectedItems = this.selectedItems.filter(function (n) {
        return n !== id
      })
    },
    delItem (selItem) {
      try {
        const msg = '"' + selItem.title + '" 파일을 삭제하시겠습니까?'

        this.$swal.fire({
          title: msg,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: '취소',
          confirmButtonText: '네, 삭제합니다',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.$firebase.firestore().collection('libraries').doc(selItem.id).delete()

            this.clearItemCheck = new Date().getTime()
          }
        })
      } catch (e) {
        console.log(e.message)
      } finally {
        //
      }
    },
    delItems () {
      if (this.selectedItems.length <= 0) return
      try {
        const msg = this.selectedItems.length + ' 개 파일을 삭제하시겠습니까?'

        this.$swal.fire({
          title: msg,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: '취소',
          confirmButtonText: '네, 삭제합니다',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            if (this.selectedItems.length > 0) {
              this.selectedItems.forEach((n) => {
                this.$firebase.firestore().collection('libraries').doc(n).delete()
              })
            }
            this.clearItemCheck = new Date().getTime()

            this.selectedItems = []
            this.allSelected = false
          }
        })
      } catch (e) {
        console.log(e.message)
      } finally {
        //
      }
    },
    preview (item) {
      // console.log('preview : ', item)
      this.selectedPreviewItem = item
      if (item.type === 'A') {
        this.previewAudioDialog = true
      } else {
        this.previewVideoDialog = true
        this.playbackYoutubeId = this.getVideoId(item.yUrl)
      }
    },
    previewPDF (item) {
      if (item.pdfFileUrl) window.open(item.pdfFileUrl, 'OpenPDF')
    },
    getVideoId (url) {
      if (!url) return ''

      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      const match = url.match(regExp)
      return (match && match[7].length === 11) ? match[7] : false
    },
    async playVideo () {
      await this.player.playVideo()
    },
    playing () {
      // console.log('we are watching!!!')
    },
    playAudioFile (index) {
      const audio = document.getElementById('audioControl')
      audio.pause()
      audio.currentTime = 0
      audio.src = ''
      if (this.selectedPreviewItem.audioFiles.length <= 0) return

      audio.src = this.selectedPreviewItem.audioFiles[index].url
      audio.load()
    }
  }
}
</script>
