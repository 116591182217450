<template>
  <div>
    <v-card outlined tile>
      <v-list>
        <v-list-item>
          <v-select
            :items="folders"
            v-model="folder"
            label="폴더를 선택하세요"
            item-text="name"
            item-value="id"
            outlined
            single-line
            class="pt-5"
            hide-details
          >
            <template slot="no-data">
              <v-alert>폴더가 없습니다</v-alert>
            </template>
          </v-select>
          <v-btn text class="ml-3 mt-3" @click="dialog = true">
            <v-icon x-large>mdi-plus</v-icon>
          </v-btn>
        </v-list-item>

        <v-list-item>
          <v-list-item-title class="mt-3">
            <v-btn-toggle
              v-model="toggle"
              tile
              color="light-green darken-4"
              group
              mandatory
            >
              <v-btn large class="mr-3">
                <v-icon color="primary">mdi-volume-high</v-icon>Audio
              </v-btn>
              <v-btn large>
                <v-icon color="red">mdi-youtube</v-icon>Youtube
              </v-btn>
            </v-btn-toggle>
          </v-list-item-title>
        </v-list-item>
        <!-- <v-list-item style="align-self: center;text-align:center;" class="py-0"> -->
          <div v-if="toggle == 1" class="pt-3" style="align-self: center;text-align:center;">
            <youtube :video-id="youtubeId" ref="youtube" @playing="playing"></youtube>
          </div>
        <!-- </v-list-item> -->
        <v-form ref="form" lazy-validation>
          <v-list-item>
            <v-file-input v-if="toggle == 0"
              v-model="audioFiles"
              @change="audioFileChange"
              prepend-icon="mdi-volume-high"
              accept="audio/*"
              :rules="[rule.required]"
              placeholder="Audio 파일을 선택하세요"
              counter
              multiple
              :show-size="1000"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip
                  v-if="index < 1"
                  color="deep-purple accent-4"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>

                <span
                  v-else-if="index === 1"
                  class="overline grey--text text--darken-3 mx-2"
                >
                  +{{ audioFiles.length - 1 }} File(s)
                </span>
              </template>
            </v-file-input>
            <v-text-field v-else
              v-model="youtubeUrl"
              :rules="[rule.youtube]"
              placeholder="https://www.youtube.com/watch?v=abCdef00Ghi"
              prepend-icon="mdi-youtube"
              @change="onUrlChange"
            ></v-text-field>

            <div class="d-none d-md-flex">
              <audio controls autoplay class="ml-3" id="audioControl" v-if="toggle == 0"></audio>
            </div>

          </v-list-item>
          <div class="text-subtitle-1 pl-4" v-if="toggle == 0">
            <v-icon>mdi-playlist-play</v-icon> <span class="text-caption">여러개 파일은 drag and drop으로 재생순서를 조정하세요</span>
            <v-card outlined v-if="audioFilesList.length > 0">

                <draggable
                  class="list-group"
                  handle=".handle"
                  v-bind="dragOptions"
                  v-model="audioFilesList"
                >
                  <transition-group type="transition" name="flip-list">

                    <template v-for="(element, index) in audioFilesList">

                      <v-list-item dense
                        :key="element.order"
                        hide-headers
                        style="border-bottom: 1px solid #d3d3d3"
                      >
                        <v-list-item-icon class="handle mr-2" style="cursor: move;align-self: auto;">
                          <v-icon>mdi-swap-vertical</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-subtitle>{{ index + 1 }}. {{ element.name }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <!-- <v-divider></v-divider> -->
                        <v-list-item-action>
                          <v-btn
                            fab
                            x-small
                            color="info"
                            dark
                            @click="playAudioFile(index)"
                          ><v-icon>mdi-play</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </transition-group>

                </draggable>

            </v-card>
          </div>
          <v-list-item v-if="toggle == 0">
            <v-file-input
              v-model="pdfFile"
              @change="pdfFileChange"
              prepend-icon="mdi-file-pdf-box"
              accept=".pdf"
              placeholder="PDF 파일을 선택하세요"
            ></v-file-input>
            <v-btn text class="ml-3 d-none d-md-flex" @click="previewPdf">
              <v-icon>mdi-view-carousel-outline</v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-text-field
              v-model="mediaTitle"
              :rules="[rule.required]"
              placeholder="제목을 입력하세요"
              prepend-icon="mdi-format-title"
              required
            ></v-text-field>
          </v-list-item>
        </v-form>
      </v-list>

      <div v-if="audioUploading" class="caption">
        Audio 파일 uploading...{{ uploadingDesc }}
      </div>
      <v-progress-linear
        :active="audioUploading"
        v-model="progressAudioUpload"
        color="amber"
        height="10"
      ></v-progress-linear>
      <div v-if="pdfUploading" class="caption">
        PDF 파일 uploading...
      </div>
      <v-progress-linear
        :active="pdfUploading"
        v-model="progressPdfUpload"
        color="amber"
        height="10"
      ></v-progress-linear>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          class="mb-5 mr-2"
          :disabled="waitForSave"
          tile outlined
          color="primary"
          @click="save"
          large
        >저장</v-btn>
      </v-card-actions>
    </v-card>

    <add-folder-card
      :dialog="dialog"
      :academyId="academyId"
      :folders="folders"
      :mediaItemsCnt="mediaItemsCnt"
      @closeDialog="closeDialog"
    ></add-folder-card>

    <v-snackbar
      v-model="snackbar"
      color="success"
      bottom
      timeout="3000"
    >
      저장되었습니다.
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
// import jsmediatags from 'jsmediatags'
import AddFolderCard from './AddFolderCard'
import draggable from 'vuedraggable'

export default {
  props: ['academyId', 'folders', 'mediaItemsCnt'],
  components: { AddFolderCard, draggable },
  data () {
    return {
      dialog: false,
      mediaTitle: '',
      folder: '',
      toggle: 0,
      youtubeUrl: '',
      // uploadImgTask: '',
      uploadAudioTask: '',
      uploadPdfTask: '',
      firebaseImageName: '',
      firebaseAudioFileName: '',
      firebasePdfFileName: '',
      // progressUpload: 0,
      progressAudioUpload: 0,
      progressPdfUpload: 0,
      audioUploading: false,
      audioUploaded: false,
      pdfUploading: false,
      pdfUploaded: false,
      audioFile: null,
      pdfFile: null,
      // duration: '',
      waitForSave: false,
      itemDocid: '',
      rule: {
        required: v => !!v || '필수 항목입니다.',
        youtube: v => {
          const pattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
          return pattern.test(v) || '올바른 youtube 주소가 아닙니다.'
        }
      },
      snackbar: false,
      youtubeId: '',
      // /////
      audioFiles: [],
      audioFilesList: [],
      totalSec: 0,
      totalSize: 0,
      uploadingDesc: ''
    }
  },
  watch: {
    // uploadImgTask: async function () {
    //   await this.uploadImgTask.on('state_changed', sp => {
    //     // this.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
    //   },
    //   null,
    //   async () => {
    //     const downloadURL = await this.uploadImgTask.snapshot.ref.getDownloadURL()
    //     if (this.itemDocid) {
    //       this.$firebase.firestore().collection('libraries').doc(this.itemDocid).update({
    //         imageUrl: downloadURL,
    //         imageName: this.firebaseImageName
    //       })
    //     }
    //   })
    // },
    uploadPdfTask: async function () {
      await this.uploadPdfTask.on('state_changed', sp => {
        this.progressPdfUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
        this.waitForSave = true
      },
      null,
      async () => {
        const downloadURL = await this.uploadPdfTask.snapshot.ref.getDownloadURL()
        if (this.itemDocid) {
          await this.$firebase.firestore().collection('libraries').doc(this.itemDocid).update({
            pdfUrl: downloadURL,
            pdfFilename: this.firebasePdfFileName
          })
        }
        this.pdfUploading = false
        this.pdfUploaded = true
        this.waitForSave = false

        // if (!this.audioUploading && this.audioUploaded) {
        //   this.clearData()
        // }
        // if (!this.audioFile) {
        //   this.clearData()
        // }
      })
    }
  },
  computed: {
    player () {
      return this.$refs.youtube.player
    },
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  methods: {
    async save () {
      if (!this.folder) {
        this.$store.commit('pop', { msg: '폴더를 선택하세요', color: 'warning' })
        return
      }

      if (this.audioFiles.length <= 0) {
        this.$store.commit('pop', { msg: 'mp3를 첨부하세요', color: 'warning' })
        return
      }

      if (!this.$refs.form.validate()) {
        return
      }

      try {
      // 1. firebase 저장
        const videoId = this.getVideoId(this.youtubeUrl)
        if (this.toggle === 1) {
          if (!videoId) {
            this.$swal.fire({
              icon: 'error',
              title: 'youtube 주소 형식이 아닙니다!'
            })

            return
          }
        }

        const newItem = {
          academy: this.academyId,
          title: this.mediaTitle,
          type: (this.toggle === 0) ? 'A' : 'Y',
          imageUrl: (this.toggle === 0) ? '' : this.getVideoThumbUrl(videoId),
          imageName: '',
          audioFiles: [],
          pdfUrl: '',
          pdfFilename: '',
          pdfFileSize: (this.pdfFile) ? this.pdfFile.size : 0,
          youtubeUrl: (this.toggle === 0) ? '' : this.youtubeUrl,
          duration: (this.toggle === 0) ? this.getDuration() : await this.getVideoDuration(videoId),
          audioFilesSeconds: (this.toggle === 0) ? this.totalSec : 0,
          audioFilesSize: (this.toggle === 0) ? this.totalSize : 0,
          folder: this.folder,
          createdAt: new Date(),
          open: 'N'
        }

        await this.$firebase.firestore().collection('libraries').add(newItem)
          .then(ref => {
            this.itemDocid = ref.id
            // console.log('libraries doc id = ', ref.id)
          })

        // 2. 이미지, audio 파일 upload
        // if ((this.audioFiles.length > 0) && (this.toggle === 0)) this.uploadCoverImg()
        if (this.pdfFile) await this.uploadPdf(this.pdfFile)

        if ((this.audioFiles.length > 0) && (this.toggle === 0)) await this.uploadAudio()

        if (this.toggle !== 0) this.snackbar = true
        if (this.toggle !== 0) this.clearData()
      } catch (e) {
        this.$swal.fire({
          icon: 'error',
          title: '미디어 등록에 실패했습니다!'
        })
        console.log(e.message)
      }
    },
    clearData () {
      const audio = document.getElementById('audioControl')
      if (audio) {
        audio.pause()
        audio.currentTime = 0
        audio.src = ''
      }

      this.audioFiles = []
      this.pdfFile = null
      this.youtubeUrl = ''

      this.audioUploaded = false
      this.pdfUploaded = false

      this.firebaseImageName = ''
      this.firebaseAudioFileName = ''
      this.firebasePdfFileName = ''

      this.$refs.form.reset()
    },
    // async uploadCoverImg () {
    //   if (this.audioFiles.length <= 0) return
    //   const file = this.audioFiles[0]
    //   await new Promise((resolve, reject) => {
    //     new jsmediatags.Reader(file)
    //       .setTagsToRead(['album', 'title', 'picture'])
    //       .read({
    //         onSuccess: (f) => {
    //           const image = f.tags.picture

    //           if (image) {
    //             let base64String = ''
    //             for (let i = 0; i < image.data.length; i++) {
    //               base64String += String.fromCharCode(image.data[i])
    //             }
    //             const base64url = 'data:' + image.format + ';base64,' + window.btoa(base64String)

    //             if (base64url) {
    //               const fileName = new Date().getTime()

    //               try {
    //                 const storageRef = this.$firebase.storage().ref()

    //                 this.uploadImgTask = storageRef.child('coverimages/' + this.academyId + '/' + fileName).putString(base64url, 'data_url')
    //                 this.firebaseImageName = fileName
    //               } catch (e) {
    //                 console.log(e.message)
    //               }
    //             }
    //           }
    //         },
    //         onError: function (error) {
    //           if (error.type === 'xhr') {
    //             console.log('There was a network error: ', error.xhr)
    //           }
    //         }
    //       })
    //   })
    // },
    uploadPdf (file) {
      const fileName = new Date().getTime()
      const extArr = file.name.split('.')
      const ext = '.' + extArr[extArr.length - 1]

      try {
        const storageRef = this.$firebase.storage().ref()

        this.uploadPdfTask = storageRef.child('pdffiles/' + this.academyId + '/' + fileName + ext).put(file)
        this.firebasePdfFileName = fileName + ext
        this.pdfUploading = true
      } catch (e) {
        console.log(e.message)
      }
    },
    async uploadAudio (file) {
      const insertForm = []
      this.audioUploading = true
      for (let i = 0; i < this.audioFilesList.length; i++) {
        const file = this.audioFiles[this.audioFilesList[i].order - 1]
        const form = {
          title: file.name,
          filename: '',
          size: file.size,
          order: i,
          url: ''
        }
        this.uploadingDesc = '(' + file.name + ')'
        await this.uploadAudioPromise(file)
          .then((res) => {
            form.filename = res.filename
            form.url = res.url
          })
        insertForm.push(form)
      }
      // console.log(insertForm)

      if (this.itemDocid) {
        await this.$firebase.firestore().collection('libraries').doc(this.itemDocid).update({
          audioFiles: insertForm
        })
      }
      this.uploadingDesc = ''
      this.audioUploading = false
      this.audioUploaded = true
      this.waitForSave = false

      if (!this.pdfFile || (!this.pdfUploading && this.pdfUploaded)) {
        this.clearData()
        this.snackbar = true
      }
    },
    async uploadAudioPromise (file) {
      return new Promise((resolve, reject) => {
        const filename = new Date().getTime()

        const storageRef = this.$firebase.storage().ref()
        const task = storageRef.child('audiofiles/' + this.academyId + '/' + filename).put(file)

        // Update progress bar
        task.on('state_changed',
          async (snapshot) => {
            this.progressAudioUpload = await Math.floor(snapshot.bytesTransferred / snapshot.totalBytes * 100)
            this.waitForSave = true
          },
          (err) => {
            console.log(err)
            reject(err)
          },
          async () => {
            const downloadURL = await task.snapshot.ref.getDownloadURL()
            resolve({ filename: filename, url: downloadURL })
          }
        )
      })
    },
    getDuration () {
      // const audio = document.getElementById('audioControl')

      // if (!audio.src) return '00:00'

      // const totalsec = Math.floor(audio.duration)
      // const min = this.leadingZeros(Math.floor(totalsec / 60), 2)
      // const sec = this.leadingZeros(totalsec % 60, 2)
      // const mmss = min + ':' + sec
      // return mmss
      if (this.totalSec <= 0) return '00:00'

      const hours = Math.floor(this.totalSec / 3600)
      const resSec = this.totalSec % 3600
      const mins = Math.floor(resSec / 60)
      const secs = resSec % 60

      let ret = ''
      if (hours > 0) {
        ret = String(hours).padStart(2, '0') + ':' + String(mins).padStart(2, '0') + ':' + String(secs).padStart(2, '0')
      } else {
        ret = String(mins).padStart(2, '0') + ':' + String(secs).padStart(2, '0')
      }

      return ret
    },
    async audioFileChange (file) {
      const audio = document.getElementById('audioControl')
      audio.pause()
      audio.currentTime = 0
      audio.src = ''
      // init
      this.totalSec = 0
      this.totalSize = 0

      if (file.length <= 0) {
        this.audioFilesList = []
        return
      }

      this.audioFilesList = this.audioFiles.map((t, index) => {
        return { name: t.name, order: index + 1 }
      })

      this.mediaTitle = file[0].name.substring(0, file[0].name.lastIndexOf('.'))

      // total seconds check
      for (let i = 0; i < this.audioFilesList.length; i++) {
        const file = this.audioFiles[this.audioFilesList[i].order - 1]
        this.totalSize = this.totalSize + file.size

        await this.totalAudioPromise(file)
          .then((res) => {
            this.totalSec = this.totalSec + Math.floor(res)
          })
      }
    },
    async totalAudioPromise (file) {
      return new Promise((resolve, reject) => {
        const audio = new Audio()
        audio.src = URL.createObjectURL(file)
        audio.onloadedmetadata = () => {
          resolve(audio.duration)
        }
      })
    },
    pdfFileChange (file) {
      //
    },
    previewPdf () {
      if (!this.pdfFile) return

      const url = URL.createObjectURL(this.pdfFile)
      window.open(url, 'OpenPDF')
    },
    leadingZeros (n, digits) {
      let zero = ''
      n = n.toString()

      if (n.length < digits) {
        for (let i = 0; i < digits - n.length; i++) { zero += '0' }
      }
      return zero + n
    },
    getVideoId (url) {
      if (!url) return ''

      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      const match = url.match(regExp)
      return (match && match[7].length === 11) ? match[7] : false
    },
    async getVideoDuration (vid) {
      if (!vid) return '00:00'

      const r = await this.$http.get('https://www.googleapis.com/youtube/v3/videos', {
        params: {
          id: vid,
          part: 'contentDetails',
          key: 'AIzaSyD1xBdatShxxUPF3_xqFsaoBLuu9TGcVYo'
        }
      })

      try {
        const { body } = r

        const vDur = body.items[0].contentDetails.duration

        const totSeconds = this.$moment.duration(vDur).asSeconds()
        if (totSeconds >= 3600) {
          // HH:MM:SS
          return this.$moment.utc(totSeconds * 1000).format('hh:mm:ss')
        } else {
          // MM:SS
          return this.$moment.utc(totSeconds * 1000).format('mm:ss')
        }
      } catch (e) {
        console.log(e.message)
        return '00:00'
      }
    },
    getVideoThumbUrl (vid) {
      if (!vid) return ''

      // const url = 'https://img.youtube.com/vi/mlJ-1AJ72ak/mqdefault.jpg'
      const url = 'https://img.youtube.com/vi/' + vid + '/default.jpg'
      return url
    },
    onUrlChange () {
      const vid = this.getVideoId(this.youtubeUrl)
      if (!vid) {
        this.$swal.fire({
          icon: 'error',
          title: 'youtube 주소 형식이 아닙니다!'
        })

        return
      }
      this.youtubeId = vid
    },
    playVideo () {
      this.player.playVideo()
    },
    playing () {
      // console.log('we are watching!!!')
    },
    closeDialog (b) {
      this.dialog = false
    },
    playAudioFile (index) {
      const audio = document.getElementById('audioControl')
      audio.pause()
      audio.currentTime = 0
      audio.src = ''
      if (this.audioFiles.length <= 0) return

      audio.src = URL.createObjectURL(this.audioFiles[this.audioFilesList[index].order - 1])
      audio.load()
    }
  }
}
</script>
