<template>
  <div>
    <v-dialog
      :value="dialog"
      width="400"
      persistent
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          폴더 추가
          <v-spacer/>
          <v-btn text small @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-list-item class="my-3">
          <v-text-field
            v-model="dialogFolder"
            placeholder="폴더명을 입력하세요"
            @keydown.enter="addFolder"
          ></v-text-field>
          <v-btn color="primary" tile outlined class="ml-3" @click="addFolder">추가</v-btn>
        </v-list-item>

        <v-card outlined tile class="text-center" v-if="folders.length <= 0">
          <v-sheet class="py-5">등록된 폴더가 없습니다</v-sheet>
        </v-card>
        <v-card outlined tile v-else>
          <v-card-text>
            <v-list-item
              v-for="(folder, i) in folders"
              :key="i"
              link
            >
              <v-list-item-icon><v-icon>mdi-folder</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="folder.name"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn color="error" text @click="delFolder(folder.id, folder.name)">삭제</v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card-text>
        </v-card>

      </v-card>
    </v-dialog>
  </div>
</template>
<script>

export default {
  props: ['dialog', 'folders', 'mediaItemsCnt', 'academyId'],
  data () {
    return {
      // dialog: false,
      dialogFolder: ''
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog', false)
    },
    addFolder () {
      if (!this.academyId) return
      if (this.dialogFolder.length <= 0) return

      const academyid = this.academyId
      const item = {
        name: this.dialogFolder
      }

      try {
        this.$firebase.firestore().collection('academies').doc(academyid)
          .collection('folders').add(item)
          .then(ref => {
          // console.log('addStudent', ref.id)
          })
      } catch (e) {
        console.log(e.message)
      } finally {
        this.dialogFolder = ''
        this.$emit('closeDialog', false)
      }
    },
    async delFolder (id, name) {
      // 폴더에 포함된 아이템 갯수는 0 이어야 삭제 가능
      // console.log(id, this.mediaItemsCnt[name])
      if (this.mediaItemsCnt[name] > 0) {
        this.$swal.fire({
          icon: 'error',
          title: '폴더에 등록된 미디어가 있습니다.'
        })
        return
      }
      // console.log(id, this.mediaItemsCnt)
      const academyid = this.academyId
      try {
        await this.$firebase.firestore().collection('academies').doc(academyid)
          .collection('folders').doc(id).delete()
      } catch (e) {
        console.log(e.message)
      }
    }
  }
}
</script>
