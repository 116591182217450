<template>
  <div>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12">
          <v-card flat>
            <v-toolbar flat></v-toolbar>
          </v-card>
          <v-card outlined tile>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  사용자 라이브러리
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn color="light-green" tile dark @click="search"><v-icon>mdi-magnify</v-icon> 조회</v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-card>

          <v-row>
            <v-col cols="4">
              <v-card outlined tile>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>사용자({{ usersCnt > 0 ? usersCnt : 0 }})</v-list-item-subtitle>
                  </v-list-item-content>

                </v-list-item>
                <v-list>
                  <v-list-item-group v-model="itemIndex" color="primary">
                    <v-list-item
                      v-for="(item, i) in users"
                      :key="i"
                      @click="selectAcademy(item, i)"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-school</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.email }} ({{ item.displayName }})
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>

            <v-col cols="8">
              <v-tabs
                background-color="grey lighten-4"
                color="deep-purple accent-4"
                grow
                v-model="activeTab"
              >
                <v-tab>라이브러리</v-tab>
                <v-tab>신규 등록</v-tab>
                <v-tab-item>
                  <library-card
                    :academyId="selectedUser.id"
                    :folders="folders"
                    :clear="clearItems"
                  ></library-card>
                </v-tab-item>
                <v-tab-item>
                  <!-- <academy-card
                    :academyId="selectedUser.id"
                    :academy="selectedAcademy"
                  ></academy-card> -->
                  <library-item-add-card
                    :academyId="selectedUser.id"
                    :folders="folders"
                    :mediaItemsCnt="mediaItemsCnt"
                  ></library-item-add-card>
                </v-tab-item>
              </v-tabs>

            </v-col>
          </v-row>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import LibraryCard from './components/LibraryCard'
import LibraryItemAddCard from './components/LibraryItemAddCard'

export default {
  components: { LibraryCard, LibraryItemAddCard },
  data () {
    return {
      users: [],
      usersCnt: 0,
      itemIndex: 0,
      selectedUser: {},
      activeTab: 0,
      folders: [],
      clearItems: '',
      mediaItemsCnt: {}
    }
  },
  methods: {
    async search () {
      this.$firebase.firestore().collection('users')
        .orderBy('email')
        .onSnapshot((sn) => {
          if (sn.empty) {
            this.users = []
          }
          this.usersCnt = sn.size

          this.users = sn.docs.map(v => {
            const item = v.data()
            return {
              id: v.id,
              email: item.email,
              emailVerified: item.emailVerified,
              displayName: item.displayName,
              acceptTerms: item.acceptTerms,
              disabled: item.disabled,
              level: item.level,
              createdAt: item.createdAt.toDate(),
              lastVisitAt: item.visitedAt.toDate()
            }
          })
          // console.log(JSON.stringify(this.users))
        })
    },
    selectAcademy (item, index) {
      this.selectedUser = item

      this.getFolders()
      this.getMediaCnt()
      this.clearItems = new Date().getTime()
    },
    getFolders () {
      this.$firebase.firestore().collection('academies').doc(this.selectedUser.id).collection('folders')
        .orderBy('name')
        .onSnapshot((sn) => {
          if (sn.empty) {
            this.folders = []
            return
          }

          this.folders = sn.docs.map(v => {
            const item = v.data()
            return {
              id: v.id,
              name: item.name
            }
          })
          // console.log('###', JSON.stringify(this.folders))
        })
    },
    async getMediaCnt () {
      await this.$firebase.firestore().collection('academies').doc(this.selectedUser.id)
        .collection('infos').doc('media')
        .onSnapshot((sn) => {
          if (sn.empty) {
            this.mediaItemsCnt = {}
          } else {
            this.mediaItemsCnt = sn.data()
            // console.log(this.mediaItemsCnt)
          }
        })
    }
  }
}
</script>
