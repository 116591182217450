<template>
  <div>
    <v-hover
      v-slot:default="{ hover }"
    >
    <v-card outlined tile :class="{ 'on-hover': hover }">
      <v-list-item>
        <v-list-item-action>
          <v-checkbox
            v-model="selected"
          ></v-checkbox>
        </v-list-item-action>
        <v-list-item-action>
          <v-img
            max-width="70"
            max-height="100"
            v-if="mediaItem.imgUrl"
            :src="mediaItem.imgUrl"
          ></v-img>
          <v-img v-else
            max-width="70"
            max-height="100"
            src="@/assets/library/mp3.png"
          ></v-img>
        </v-list-item-action>
        <v-list-item-content class="mx-5">
          <v-list-item-subtitle class="mb-2">
            <span v-if="mediaItem.type === 'A'" class="primary--text">Audio</span>
            <span v-else class="red--text">Youtube</span>
          </v-list-item-subtitle>
          <v-list-item-title>{{ mediaItem.title }}</v-list-item-title>
          <v-list-item-subtitle>
            <v-row>
              <v-col cols="6">
                <div>
                  <v-icon>mdi-folder</v-icon>
                  <span style="vertical-align: middle">{{ mediaItem.folder }}</span>
                </div>
              </v-col>
              <v-col cols="6" align-self="center">
                <div>
                  <v-icon>mdi-clock-outline</v-icon>
                  <span style="vertical-align: middle">{{ mediaItem.dur }}</span>
                </div>
              </v-col>
            </v-row>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="mr-3">
          <v-btn
            small fab
            class="primary"
            v-if="mediaItem.audioFiles.length > 0 || mediaItem.yUrl"
            @click="preview"
          >
            <v-icon>mdi-play</v-icon>
          </v-btn>
          <v-btn
            small fab
            class="grey"
            disabled
            v-else
          >
            <v-icon>mdi-play</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action>
          <v-btn
            small fab
            class="error"
            v-if="mediaItem.pdfFileUrl"
            @click="previewPDF"
          >
            <v-icon>mdi-file-pdf-box</v-icon>
          </v-btn>
          <v-btn
            small fab
            class="grey"
            disabled
            v-else
          >
            <v-icon>mdi-file-pdf-box</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action>
          <v-layout row class="mx-0">
            <v-flex xs2 class="text-sm-right">
              <v-menu offset-y min-width="80">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <!-- <v-list-item @click="goEdit">
                    <v-list-item-title class="text-center">수정</v-list-item-title>
                  </v-list-item> -->
                  <v-list-item @click="goDel">
                    <v-list-item-title class="text-center">삭제</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

            </v-flex>
          </v-layout>
        </v-list-item-action>
      </v-list-item>
    </v-card>
    </v-hover>
  </div>
</template>
<script>
export default {
  props: ['mediaItem', 'allSelected', 'clear'],
  data () {
    return {
      selected: false
    }
  },
  watch: {
    selected: function () {
      // console.log('watch selected', this.selected)
      if (this.selected) {
        this.set()
      } else {
        this.reset()
      }
    },
    allSelected: function () {
      this.selected = this.allSelected
    },
    clear (str) {
      // console.log('clear : ', str)
      this.selected = false
    }
  },
  methods: {
    // goEdit () {
    //   this.$emit('editItem', this.mediaItem)
    // },
    goDel () {
      this.$emit('delItem', this.mediaItem)
    },
    set () {
      // console.log(this.mediaItem.id + ' selected')
      this.$emit('setItem', this.mediaItem.id)
    },
    reset () {
      // console.log(this.mediaItem.id + ' un-selected')
      this.$emit('resetItem', this.mediaItem.id)
    },
    preview () {
      this.$emit('preview', this.mediaItem)
    },
    previewPDF () {
      this.$emit('previewPDF', this.mediaItem)
    }
  }
}
</script>
<style scoped>

.on-hover {
  border: 1px solid #4187F9;
}
</style>
